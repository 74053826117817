<script>
  import { mapGetters, mapActions } from 'vuex'
  import { mixin as clickaway } from 'vue-clickaway2'

  export default {
    name: 'CatalogComponent',
    mixins: [clickaway],
    props: {
      show: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        current: 666
      }
    },
    methods: {
      ...mapActions('catalogMenu', ['updateState']),
      isSection (link) {
        return this.currentFilter(link.id).length > 0
      },
      currentFilter (id) {
        return this.menu.filter((link) => {
          return (link.parent === id && link.parent !== link.id)
        })
          .sort((i, j) => {
            return (i.title > j.title) ? 1 : (i.title < j.title) ? -1 : 0
          })
      }
    },
    computed: {
      ...mapGetters(['baseURL']),
      ...mapGetters('catalogMenu', ['menu']),
      currentLink () {
        return this.menu.find((link) => {
          return link.id === this.current
        })
      },
      currentMenu () {
        return this.currentFilter(this.current)
      },
      rootMenu () {
        const menu = [
          {
            id: '240',
            group: '2',
            sort: 8,
            title: 'Электробензоинструмент',
            parent: 0,
            url: '/catalog/240',
            icon: this.baseURL + '/images/sct/img_kat1/elektrobenzoinstrument.png'
          },
          {
            id: '891',
            group: '3',
            sort: 14,
            title: 'Бытовая техника',
            parent: 0,
            url: '/catalog/891',
            icon: this.baseURL + '/images/sct/img_kat1/bytovaya_tekhnika.png'
          },
          {
            id: '504',
            group: '2',
            sort: 10,
            title: 'Туризм и отдых',
            parent: 0,
            url: '/catalog/504',
            icon: this.baseURL + '/images/sct/img_kat1/turizm_otdykh.png'
          },
          {
            id: '420',
            group: '2',
            sort: 6,
            title: 'Сад и огород',
            parent: 0,
            url: '/catalog/420',
            icon: this.baseURL + '/images/sct/img_kat1/sad-ogorod.png'
          },
          {
            id: '1016',
            group: '3',
            sort: 13,
            title: 'Текстиль',
            parent: 0,
            url: '/catalog/1016',
            icon: this.baseURL + '/images/sct/img_kat1/tekstil.png'
          },
          {
            id: '1058',
            group: '3',
            sort: 15,
            title: 'Товары для детей',
            parent: 0,
            url: '/catalog/1058',
            icon: this.baseURL + '/images/sct/img_kat1/tovary_dlya_detey.png'
          },
          {
            id: '1097',
            group: '4',
            sort: 16,
            title: 'Хозтовары',
            parent: 0,
            url: '/catalog/1097',
            icon: this.baseURL + '/images/sct/img_kat1/khoztovary.png'
          },
          {
            id: '1263',
            group: '4',
            sort: 18,
            title: 'Гигиена и уход',
            parent: 0,
            url: '/catalog/1263',
            icon: this.baseURL + '/images/sct/img_kat1/gigiena.png'
          },
          {
            id: '1037',
            group: '4',
            sort: 19,
            title: 'Одежда, обувь',
            parent: 0,
            url: '/catalog/1037',
            icon: this.baseURL + '/images/sct/img_kat1/odezhda.png'
          },
          {
            id: '698',
            group: '2',
            sort: 9,
            title: 'Автотовары',
            parent: 0,
            url: '/catalog/698',
            icon: this.baseURL + '/images/sct/img_kat1/autotovaty.png'
          },
          {
            id: '954',
            group: '3',
            sort: 12,
            title: 'Предметы интерьера',
            parent: 0,
            url: '/catalog/954',
            icon: this.baseURL + '/images/sct/img_kat1/predmety_interyera.png'
          },
          {
            id: '163',
            group: '1',
            sort: 3,
            title: 'Сантехника. Вентиляция. Газ',
            parent: 0,
            url: '/catalog/163',
            icon: this.baseURL + '/images/sct/img_kat1/santekhnika.png'
          },
          {
            id: '753',
            group: '3',
            sort: 11,
            title: 'Посуда',
            parent: 0,
            url: '/catalog/753',
            icon: this.baseURL + '/images/sct/img_kat1/posuda.png'
          },
          {
            id: '99',
            group: '1',
            sort: 2,
            title: 'Стройка и отделка',
            parent: 0,
            url: '/catalog/99',
            icon: this.baseURL + '/images/sct/img_kat1/stroyaka-otdelka.png'
          },
          {
            id: '641',
            group: '1',
            sort: 4,
            title: 'Электрика и освещение',
            parent: 0,
            url: '/catalog/641',
            icon: this.baseURL + '/images/sct/img_kat1/elektrika.png'
          },
          {
            id: '78',
            group: '1',
            sort: 1,
            title: 'Лаки. Краски. Клеи',
            parent: 0,
            url: '/catalog/78',
            icon: this.baseURL + '/images/sct/img_kat1/laki-kraski.png'
          },
          {
            id: '321',
            group: '2',
            sort: 7,
            title: 'Инструмент',
            parent: 0,
            url: '/catalog/321',
            icon: this.baseURL + '/images/sct/img_kat1/instrumenty.png'
          },
          {
            id: '613',
            group: '1',
            sort: 5,
            title: 'Замочно-скобяные изделия',
            parent: 0,
            url: '/catalog/613',
            icon: this.baseURL + '/images/sct/img_kat1/zamochno-skobyanye.png'
          },
          {
            id: '1194',
            group: '4',
            sort: 17,
            title: 'Бытовая химия и косметика',
            parent: 0,
            url: '/catalog/1194',
            icon: this.baseURL + '/images/sct/img_kat1/bytovaya_khimiya.png'
          },
          {
            id: '107339',
            group: '4',
            sort: 27,
            title: 'Товары для праздников',
            parent: 0,
            url: '/catalog/107339',
            icon: this.baseURL + '/images/sct/img_kat1/new-year.png'
          }
        ].sort((a, b) => {
          return a.sort > b.sort ? 1 : (a.sort === b.sort) ? 0 : -1
        })

        const groups = {}
        menu.map((i) => {
          if (groups[i.group] === undefined) {
            groups[i.group] = []
          }
          groups[i.group].push(i)
        })
        return groups
      }
    },
    mounted () {
      this.updateState()
    }
  }
</script>

<template lang="html">
    <div class="c-catalog v-component" v-on-clickaway="() => $emit('close')">
        <div class="row" :class="bem('root')">
            <div class="container">
                <div class="row">
                    <ul class="menu col-12 col-sm-6 col-xl-3" v-for="group in rootMenu">
                        <li class="item row mb-2" v-for="link in group">
                            <div class="col">
                                <a class="btn btn-secondary btn-block button text-left"
                                   href="#"
                                   @click.prevent="current = link.id" v-scroll-to="'#menu, 30px'">
                                    <img class="i-png mr-2" :src="link.icon"/>
                                    {{ link.title }}
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row" :class="bem('menu')" ref="menu">
            <div class="container" id="menu">
                <nuxt-link class="current section-link"
                           :to="currentLink.url"
                           v-if="currentLink"
                           v-on-clickaway="() => $emit('close')">
                    {{ currentLink.title }}
                </nuxt-link>
                <ul class="menu">
                    <li class="item" v-for="link in currentMenu">
                        <div class="block" v-if="isSection(link)">
                            <a class="link"
                               v-if="current === 0"
                               href="#"
                               @click.prevent="current = link.id">
                                {{ link.title }}
                            </a>
                            <div class="sub" v-else>
                                <nuxt-link class="sct-title section-link"
                                           :to="link.url"
                                           v-on-clickaway="() => $emit('close')">
                                    {{ link.title }}
                                </nuxt-link>
                                <hr class="divider"/>
                                <ul class="menu">
                                    <li class="item" v-for="subLink in currentFilter(link.id)">
                                        <span @click="$emit('close')">
                                            <nuxt-link class="link" :to="subLink.url">
                                                {{ subLink.title }}
                                            </nuxt-link>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <nuxt-link class="link" v-else :to="link.url">{{ link.title }}</nuxt-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import '~assets/scss/mixins';
    // $debug: true;
    @include component('catalog') {
        @include element('root') {
            @include clearfix;
            @include media-breakpoint-down('lg') {
                padding-top: $spacer * 1.5;
            }
            background: $sct-background-color-dark-transparent;
            border-bottom: 1px solid darken($gray-lighter, 5%);
            margin-bottom: -1px;
            padding-bottom: $spacer;
            padding-top: $spacer * 2;
            .menu {
                list-style: none;
                margin-bottom: 0;
            }
            .item {
                img {
                    filter: saturate(0) brightness(.5);
                    height: $spacer;
                }
            }
            .button {
                @include hfas('active') {
                    background-color: $brand-warning;
                }
            }
        }
        @include element('menu') {
            background: $sct-background-color-light;
            margin-bottom: 0;
            padding-left: 0;
            padding-right: 0;
            .menu {
                @include clearfix;
                list-style: none;
                margin-bottom: $spacer * 2;
                padding-left: 0;

                &:empty {
                    margin-bottom: 0;
                }
            }
            .block {
                @include clearfix;
            }
            .section-link {
                display: inline-block;
                margin-bottom: 0.5rem;
                font-weight: 500;
                line-height: 1.1;
                color: #292b2c;
                text-decoration: none;

                &.current {
                    margin-top: $spacer * 1.5;
                    font-size: 2rem;
                }
            }
            .sub {
                @include clearfix;
                margin-bottom: 0;

                .sct-title {
                    margin-top: $spacer;
                    font-size: 1.5rem;
                }

                .menu {
                    @include clearfix;
                    list-style: none;
                    margin-bottom: $spacer / 2;
                    padding-left: 0;

                    .item {
                        display: inline-block;
                        margin-right: $spacer * 1.5;
                        width: auto;

                        .link {
                            @include hfas('active') {
                                border-bottom: 1px dashed darken($brand-primary, 15%);
                                color: darken($brand-primary, 15%);
                                text-decoration: none;
                            }
                            border-bottom: 1px dashed $brand-primary;
                            color: $gray;
                            padding-bottom: $spacer / 4;
                        }
                    }
                }
            }
            .item {
                display: inline-block;
                line-height: 2;
                margin-right: $spacer;
                padding: 0;
                width: 100%;

                .link {
                    @include hfas('active') {
                        color: $gray-dark;
                    }
                    color: $gray;
                }
            }
            .divider {
                margin-bottom: $spacer / 2;
                margin-top: $spacer / 1.5;
            }
        }
        &.v-component {
        }
    }
</style>
